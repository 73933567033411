:root {
    --padding: 1%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 500px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    --fixed-header-height: 10vh;
    
    /* change settings for headings */
    --header-bg-color: transparent;
    --header-padding: 0 0 0.5em 0;
    
    
    /* change colors for topmenu */
    --topmenu-bg: white;
    --topmenu-color: #b09967;
    --topmenu-li-a-padding: 10px 15px;
    
        /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 14px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;

}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/OpenSans.css";

/*
@import "/theme/intendit/css/modules/photoswipe.css";
@import "/theme/intendit/css/modules/animate.css";
*/

/* general
---------------------------------------------------------*/
/*--------------------------------------------------------------
Specialbutton
--------------------------------------------------------------*/
a.button.specialbutton {
    background: #191919;
    font-size: 1rem;
    border: #fff solid 2px;
    box-shadow: 0 4px 4px rgba(0,0,0,.4);
    width: auto;
    margin: 1em;
    color: #fff;
    font-weight: 400;
}
.button {
    margin: 5px;
    background: #222;
    border: #222 solid 1px;
    color: white;
    border-radius: 20px;
    width: 100%;
}
.button, button, input[type=reset], input[type=submit] {
    text-decoration: none;
    border-radius: .2em;
    cursor: pointer;
    display: inline-block;
    margin: 1em;
    padding: .5em 1em;
    text-align: center;
    outline: 0;
    border: 2px solid;
    background: 0 0;
}

section {
    min-height:50vh;
    padding:5% 0;
}

.white {
    background-color:white;
}

.gray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}
.black {
    background-color:#000;
}

#menu-icon {
    z-index:100;
}

#menu-icon span {
    border:2px solid black;
}

.page {
    min-height:85vh;
}
 

/* header
---------------------------------------------------------*/
header {
    background:var(--header-bg-color);
    text-transform:none;
    padding:var(--header-padding);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
    width:100%;
    z-index:100;
}

.startsida header {
    display:none;
}

header .container {
    padding:0 0.5em;
}

header a {
    color:black;
}

.menu li {
    margin:0;
}

.menu li a {
    padding:var(--topmenu-li-a-padding);
    color:black;
}

.menu li a:hover, .menu .active a {
    background: var(--topmenu-bg);
    color:var(--topmenu-color);
}

.menu .dropdown ul {
    background:#fff;
    z-index:100;
}

.logo {
    padding:0;
}

.logo a {
    font-size:0;
}

.startsida .logo {
    display:none;
}

.booking {
    border-bottom:#eee dotted 1px;
    padding-bottom:0.6em;
}

.booking a {
    background: #F28D1A;
    border:#F28D1A solid 1px;
    border-top-left-radius:0px;
    border-top-right-radius:0px;
}

.booking a:hover {
    background:black;
    border:black solid 1px;
    color:white;
}

.menu {
    padding-top:0.6em !important;
    margin-top:0.6em;
}

/* slider
---------------------------------------------------------*/

.start-bildspel::before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: '';
    width: 96%;
    height: 92%;
    border: 1px solid #3A3A3A;
    z-index: 2;
    margin: auto;  
}

.slick-slider {
    margin:0 auto;
    position: relative;
}
.startsida .slick-track {
    height: 100vh;
}

.slick-slide {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
} 


.slider-content .container {
    max-width:880px;
}

.slider-content span {
    display:block;
}

.slider-content span {
    display:block;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    text-align:center;
    font-weight:700;
    color:white;
    font-size:2.0rem;
}

.slider-content-txt {
    font-size:1.6rem !important; 
    font-weight:normal;
    color:white;
}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    font-size:5rem;
}

.slick-dots {
    bottom:5vh;
    z-index:10000;
}

.slick-dots li button:before {
    font-size:0rem;
    opacity:1.0;
    color:#b39966;
    width:40px;
    height:8px;
}

.slick-dots li.slick-active button:before {
    background:#b39966;
    display:block;
}

.slick-dots li {
    border:#b39966 solid 1px;
    border-radius:0%;
    margin:0 2px;
    width:40px;
    height:8px;
}

/* start-block-wrapper
---------------------------------------------------------*/

.start-block-wrapper {
    position:relative;
}

.start-block-wrapper .introtext {
    position:absolute;
    left:0;
    right:0; 
    top:20vh;
    text-align:center;
    max-width:800px;
    height: calc(100vh - 300px);
}

.start-block-wrapper .introtext h1, .start-block-wrapper .introtext h2 {
    color:white;
}

.start-block-wrapper .introtext h1 {
    font-weight:800;
    text-transform:uppercase;
    letter-spacing:-1px;
    font-size:2.8rem;
    margin-bottom:0.5em;
}

.start-block-wrapper .introtext {
    color:white;
    z-index: 99;
}


/* zoomboxar
---------------------------------------------------------*/
.zoomboxar { width:100%; z-index:10001; }
.zoombox { height:15vw; overflow:hidden; position:relative; color:#fff; text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4); margin-bottom:0; box-shadow: 0px 0px 0px 2px #fff;}
.zoombox a { -webkit-transition: color 3s ease-in-out 0s; transition: color 3s ease-in-out 0s;}
.zoombox a:hover { color: rgba(255, 255, 255, 1); }
.zoombox_innerdiv {height:100%; width:100%; background-position: 50% 50%; background-size:cover; transform: scale(1); position:absolute; transition: transform 3s ease-in 0s;}
.zoomboxar > a:hover .zoombox_innerdiv {transform: scale(1.2);transition: transform 3s ease-in 0s;} 
.zoombox_textdiv {height:100%; width: 100%; overflow:hidden; margin:0;  padding: 0px 2em; text-align:center; position:absolute;} 

.zoombox h2 {padding-bottom: 35px; position: relative; text-align: center; text-shadow:0px 0px 20px rgba(0, 0, 0, 0.4); font-weight:700; }
.zoombox:hover h2:after {opacity:1; transition:0.5s ease-in 0s; }
.zoombox h2 span { display:block; font-size:1.2rem; padding-top:0.5em;}

.zoombox h2 { color: rgba(255, 255, 255, 0.4); -webkit-transition: color 1s ease-in-out 0s; transition: color 1s ease-in-out 0s; display:block;}
.zoombox h2:hover { color: rgba(255, 255, 255, 1);}


/* page
---------------------------------------------------------*/
.content .flex h1 {
    margin-bottom:0;
}

.kalender ul {
    list-style:none;
    margin:0;
    padding:0;
}
.kalender ul .inner {
    overflow: hidden;
    display: none;
}

.kalender a {
    text-decoration:none;
}

.kalender ul li {
  margin: .5em 0;
}

.kalender ul li a.toggle {
  width: 100%;
  display: block;
  background: rgba(0, 0, 0, 0.78);
  color: #fefefe;
  padding: .75em;
  border-radius: 0.15em;
  -webkit-transition: background .3s ease;
          transition: background .3s ease;
}

.kalender ul li a.toggle:hover {
  background: rgba(0, 0, 0, 0.9);
}

.kalender ul li:first-child ul {
    display:block;
}


.kalender .inner.show {
    margin:0;
    padding:0 0 0 1em;
}

.kalender a {
    color:black;
    display:block;
    padding:0.3em 0;
    border-bottom:#B19361 dotted 1px;
}

.kalender a:hover {
    color:#B19361;
}

.calendar-heading {
    font-weight:700;
}

.contact-heading {
    display:block;
    font-size:1.2rem;
}

.contact-info ul {
    list-style:none;
    margin:0.5em 0 0 0;
    padding:0;
}
.contact-info li {
    padding-bottom:0.3em;
}

hr {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0;
    border: none;
    height: 1px;
    background-color: #993300;
    clear: both;
}



/* Footer
---------------------------------------------------------*/

.startsida footer {
    display:none;
}

footer {
    color:#b7b7b7;
}

footer a {
    color: #b7b7b7;
    text-decoration:none;
}

footer a:hover {
    color:#fff;
}

.socialamedia a {
    font-size:2.0rem;
    padding-right:0.5em;
}

footer ul {
    margin:1em 0 0 0;
    padding:0;
    list-style:none;
}

footer li {
    display:block;
    padding: 0.5em 0;
    text-align:center;
}

footer .contact-info {
    font-size:0.8rem;
}


.created-by {
    background:#1D1D1D;
    padding:0.8em 0;
    font-size:0.7rem;
}

.created-by .container, .intenditfooter {
    padding:0;
    margin:0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
    color:#565656;
}

.adress span, .c-info span {
    display:block;
}

.c-info {
    margin-top:1em;
}

.c-form {
    background:#9c9c9c;
}

.c-form-inner {
    padding:1.3em;
}

.c-form-inner button[type=submit], .c-form-inner input[type=submit] {
    background:black;
    border:black solid 1px;
}

.c-form-inner button[type=submit]:hover, .c-form-inner input[type=submit]:hover {
    background:#F28D1A;
    border:#F28D1A solid 1px;
}

/* other
---------------------------------------------------------*/
.fa-map-marker:before {
    font-size:3rem;
}

.boltforms-fleft-row {
    display:inline-block;
    max-width:48%;
    width:100%;
}

.boltforms-fleft-row label {
    font-size:0.9rem;
}


::-webkit-input-placeholder {
   color: #9c9c9c;
}

:-moz-placeholder { /* Firefox 18- */
   color: #9c9c9c;  
}

::-moz-placeholder {  /* Firefox 19+ */
   color: #9c9c9c;  
}

:-ms-input-placeholder {  
   color: #9c9c9c;  
}

/*.boltform label {
    display: none;
}*/

.boltform {
    width:100%;
}

.boltform textarea {
    min-height:120px;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width:100%;
    color:#222;
}

button[type=submit], input[type=submit], .button {
    margin:0;
    background:#F28D1A;
    border:#F28D1A solid 1px;
    color:white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

button[type=submit]:hover, input[type=submit]:hover, .button {
    background:#000;
    border:#000 solid 1px;
    color:white;
}


a {
    color:#993300;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color:#000;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

body {
    font-family: OpenSans;
    font-weight: 400;
    color:#282d32;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

.container {
    max-width: 1100px;
    padding: 0.5em;
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
}

/* Give map a height and it's images no max-width */
.map-canvas {
    height: 50vh;
}

.map-canvas img {
    max-width: none;
}

h1, h2, h3, h4, h5, h6 {
    color:#000;
    margin-top:0;
    margin-bottom:0.3em;
}

h1 {
      font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

h2 {
  font-size: calc(1 * var(--scale-factor)rem);

}

h3 {
      font-size: 1.2rem;
    
}

h2 a {
    color: #fff;
}

/* Make FB widget responsive */
.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget iframe[style],
.twitter-timeline {
    width: 100% !important;
}
.gmaps iframe {
    width:100% !important;
}

/* Example media queries*/
@media (max-width: 400px) {}

@media (max-width: 550px) {
    .zoombox, .zoomkatboxar, .zoomkatboxar .zoombox {
        height:70vw;
    }
    .homepage header {
        background:transparent;
    }
    header {
        padding:0.5em;
    }
}

@media (max-width: 750px) and (min-width: 551px ) {
    .zoombox, .zoomkatboxar, .zoomkatboxar .zoombox {
        height:40vw;
    } 

}

@media (max-width: 800px) {
    .bildspel_titel .container {
        font-size:1.1em;
    }
    .zoombox h1, .zoombox h2 {
        font-size:1.7rem;
    }
    .start-block-wrapper .introtext {
        position:relative;
        width:100%;
        height:auto;
        top:0;
        padding:0 2em;
    }
    .start-bildspel {
        padding:0 2em;
    }
    
    .slick-slide {
        background-size:contain;
        background-position: top center;
        background-repeat:no-repeat;
        display: flex !important;
        align-items: center;
        justify-content: top;
    } 
    .startsida .slick-track {
        height:50vw;
    }
    .start-bildspel::before {
        height:auto;
    }
    
}
@media (max-width:var(--breakpoint-menu)) {
    .menu.show, .menu {
        background:#fff !important;
        box-shadow: 0 4px 2px -2px gray;
    }
    .menu li, .menu li ul, .menu li:hover ul {
        margin:0;
    }
    .menu li a {
        color: black;
    }
    #menu-icon {
        position:absolute;
        top:8px;
        right:10px;
    }
    .booking {
        display:none;
    }
    footer .booking {
        display:block;
    }
}


@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
}

@media (max-width: var(--breakpoint-xs)) {
    h1 {
        font-size: calc(1 * var(--scale-factor-xs) * var(--scale-factor-xs)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }
}
